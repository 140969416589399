import React, { useState, useEffect, useRef } from "react";
import {
  Alert,
  Badge,
  Button,
  ButtonGroup,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import "./brand-edit.scss";
import * as Yup from "yup";
import { question, toast } from "../../../helpers/functions/swal";
import { useFormik } from "formik";
import {
  deleteBrandById,
  getBrandById,
  updateBrandById,
} from "../../../api/brand-service";
import { settings } from "../../../helpers/setting";
import { uploadImage } from "../../../api/image-service";
import Loading from "../../common/loading/loading";
import AdminPageTitle from "../common/admin-page-title";

let imageChanged = false;

const AdminBrandEdit = () => {
  const [loading, setLoading] = useState(true);
  const [imageSrc, setImageSrc] = useState("");
  const [deleting, setDeleting] = useState(false);
  const [updating, setUpdating] = useState(false);

  const navigate = useNavigate();

  const { brandId } = useParams();
  const fileImageRef = useRef();

  const [initialValues, setInitialValues] = useState({
    name: "",
    status: "",
    image: "",
    builtIn: false,
  });

  const validationSchema = Yup.object({
    name: Yup.string()
      .required("Please enter the title")
      .min(3, "Please enter at least 3 characters")
      .max(70, "Please enter the most 70 characters"),
    image: Yup.mixed().required("Please select an image"),
  });

  const loadData = async () => {
    try {
      const resp = await getBrandById(brandId);
      setInitialValues(resp.data);
      setImageSrc(`${settings.apiURL}/image/display/${resp.data.image}`);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (values) => {
    setUpdating(true);
    try {
      if (imageChanged) {
        const newImageFile = fileImageRef.current.files[0];
        const formData = new FormData();
        formData.append("image", newImageFile);
        const resp = await uploadImage(formData);
        const imageId = resp.data.imageId[0];
        values.image = imageId;
        imageChanged = false;
      }
      await updateBrandById(brandId, values);
      toast("Brand was saved", "success");
      navigate(-1);
    } catch (err) {
      toast(err.response.data.message, "error");
    } finally {
      setUpdating(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const handleChangeImage = (e) => {
    const file = fileImageRef.current.files[0];
    if (!file) return;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setImageSrc(reader.result);
    };
    imageChanged = true;
  };

  const removeImage = async () => {
    setDeleting(true);
    try {
      await deleteBrandById(brandId);
      toast("Brand was deleted", "success");
      navigate(-1);
    } catch (err) {
      toast(err.response.data.message, "error");
    } finally {
      setDeleting(false);
    }
  };

  const handleDelete = () => {
    question("Are you sure to delete?", "You won't be able to undo it!").then(
      (result) => {
        if (result.isConfirmed) {
          removeImage();
        }
      }
    );
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <Container className="brands-edit">
      <AdminPageTitle titleEdit={`${formik.values.name}`} />
      <Form noValidate onSubmit={formik.handleSubmit} className="mt-5">
        <fieldset disabled={formik.values.builtIn}>
          <Row className="g-5">
            <Col md={2} className="image-area">
              <img src={imageSrc} alt="" className="img-fluid" />
              <Form.Group controlId="fileSelect">
                <Form.Control
                  type="file"
                  name="image"
                  accept=".jpg, .jpeg, .png, .webp"
                  onChange={handleChangeImage}
                  className="d-none"
                  ref={fileImageRef}
                />
                <Form.Label className="select-img" style={{opacity: formik.values.builtIn ? "0.65" : "1", cursor:  formik.values.builtIn ? "default" : "pointer" }}>Select Image</Form.Label>
              </Form.Group>

              <Badge bg="secondary" className="image-error">
                {formik.errors.image}
              </Badge>
            </Col>
            <Col md={9} className="input-select">
              <Form.Group className="mb-4">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  placeholder="Type something"
                  type="text"
                  {...formik.getFieldProps("name")}
                  isValid={formik.touched.name && !formik.errors.name}
                  isInvalid={formik.touched.name && !!formik.errors.name}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.name}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Check
                  type="switch"
                  label="Active"
                  checked={formik.values.status === "PUBLISHED"}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "status",
                      e.target.checked ? "PUBLISHED" : "NOT_PUBLISHED"
                    )
                  }
                />
              </Form.Group>
              <div className="text-end">
                <ButtonGroup className="mt-5">
                  <Button
                    variant="secondary"
                    onClick={handleDelete}
                    disabled={deleting}
                  >
                    {deleting && <Spinner animation="border" size="sm" />}{" "}
                    Delete
                  </Button>
                  <Button variant="primary" type="submit" disabled={updating}>
                    {updating && <Spinner animation="border" size="sm" />}{" "}
                    Update
                  </Button>
                </ButtonGroup>
              </div>
            </Col>
          </Row>
        </fieldset>
      </Form>
      {formik.values.builtIn ? (
        <>
          <div className="alert">
            <Alert variant="danger" className="mt-5">
              Built-in brand cannot be deleted or updated
            </Alert>
          </div>
          <div className="alert">
            <Alert variant="warning">
              Make sure that the height of the images you want to upload is
              100px.
            </Alert>
          </div>
        </>
      ) : (
        <div className="alert">
          <Alert variant="warning" className="mt-5">
            Make sure that the height of the images you want to upload is 100px.
          </Alert>
        </div>
      )}
    </Container>
  );
};

export default AdminBrandEdit;
