import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  ButtonGroup,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import "./coupon-edit.scss";
import * as Yup from "yup";
import { question, toast } from "../../../helpers/functions/swal";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteCouponById,
  getCouponsById,
  updateCouponById,
} from "../../../api/coupon-service";
import AdminPageTitle from "../common/admin-page-title";

const CouponEdit = () => {
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [infinityState, setInfinityState] = useState(false);
  const { couponId } = useParams();
  const navigate = useNavigate();

  const [initialValues, setInitialValues] = useState({
    code: "",
    amount: "",
    type: "",
    life: "",
    status: "",
  });

  const validationSchema = Yup.object({
    amount: Yup.number()
      .integer()
      .min(0, "Please enter a positive integer or 0 for your amount")
      .required("Please enter your amount"),
    type: Yup.string().required("Please enter your percentage"),
    life: Yup.number()
      .test(
        "life",
        "Please enter a positive number or -1",
        (value) => value === -1 || (value > 0 && Number.isInteger(value))
      )
      .required("Please enter coupon life"),
    code: Yup.string()
      .required("Please enter the code")
      .test(
        "includes_",
        "Please enter a valid code",
        (val) => val && !val.includes("_")
      ),
  });

  const loadData = async () => {
    setLoading(true);
    try {
      const resp = await getCouponsById(couponId);
      setInitialValues(resp.data);
      setInfinityState((prev) => (resp.data.life === -1 ? true : false));
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      delete values.code;
      await updateCouponById(couponId, values);
      toast("Coupon was updated", "success");
      navigate(-1);
    } catch (err) {
      toast(err.response.data.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const removeCouponEdit = async () => {
    setDeleting(true);
    try {
      await deleteCouponById(couponId);
      toast("coupon was deleted", "success");
      navigate(-1);
    } catch (err) {
      toast(err.response.data.message, "error");
    } finally {
      setDeleting(false);
    }
  };

  const handleDelete = () => {
    question("Are you sure to delete?", "You won't be able to undo it!").then(
      (result) => {
        if (result.isConfirmed) {
          removeCouponEdit();
        }
      }
    );
  };

  const handleInfinityChange = () => {
    formik.values.life = infinityState ? 0 : -1;
    setInfinityState((prev) => (prev ? false : true));
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  return (
    <Container className="coupon-edit">
      <AdminPageTitle titleEdit={`${formik.values.code}`} />
      <Form noValidate onSubmit={formik.handleSubmit}>
        <Row className="my-5">
          <Col sm={6}>
            <Form.Group className="mb-3">
              <Form.Label>Code</Form.Label>
              <Form.Control
                type="text"
                value={formik.values.code}
                disabled
              />
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group className="mb-3">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="number"
                {...formik.getFieldProps("amount")}
                isValid={formik.touched.amount && !formik.errors.amount}
                isInvalid={formik.touched.amount && !!formik.errors.amount}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.amount}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={6} md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Type</Form.Label>
              <Form.Select
                {...formik.getFieldProps("type")}
                isValid={formik.touched.type && !formik.errors.type}
                isInvalid={formik.touched.type && !!formik.errors.type}
              >
                <option>Select</option>
                <option value="PERCENTAGE_AMOUNT">Percentage</option>
                <option value="EXACT_AMOUNT">Exact</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.type}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col sm={6} md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Life</Form.Label>
              <InputGroup>
                <Form.Control
                className="life"
                  type="number"
                  {...formik.getFieldProps("life")}
                  disabled={infinityState}
                  isValid={formik.touched.life && !formik.errors.life}
                  isInvalid={formik.touched.life && !!formik.errors.life}
                />
                <Form.Check
                  type="checkbox"
                  className="checkbox"
                  onChange={handleInfinityChange}
                  checked={infinityState}
                />
                <InputGroup.Text className="infinity">Infinity</InputGroup.Text>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.life}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col sm={12} md={4}>
            <Form.Check
              type="switch"
              label="Active"
              checked={formik.values.status === "ACTIVE"}
              onChange={(e) =>
                formik.setFieldValue(
                  "status",
                  e.target.checked ? "ACTIVE" : "PASSIVE"
                )
              }
            />
          </Col>
        </Row>
        <div className="text-end">
          <ButtonGroup className="mt-5">
            <Button
              variant="secondary"
              onClick={handleDelete}
              disabled={deleting}
            >
              {deleting && <Spinner animation="border" size="sm" />} Delete
            </Button>
            <Button
              type="submit"
              disabled={!(formik.dirty && formik.isValid) || loading}
            >
              {loading && <Spinner animation="border" size="sm" />} Save
            </Button>
          </ButtonGroup>
        </div>
      </Form>
    </Container>
  );
};

export default CouponEdit;
