import axios from "axios";
import authHeader from "../helpers/functions/auth-header";
import { settings } from "../helpers/setting";

const API_URL = settings.apiURL;

export const newBrand = (imageId, newBrandData) => {
  return axios.post(`${API_URL}/brands/${imageId}`, newBrandData, {
    headers: authHeader(),
  });
};

export const updateBrandById = (id, updateBrandData) => {
  return axios.put(`${API_URL}/brands/${id}`, updateBrandData, {
    headers: authHeader(),
  });
};

export const deleteBrandById = (id) => {
  return axios.delete(`${API_URL}/brands/${id}`, { headers: authHeader() });
};

export const getBrandsByPage = (
  q = "",
  status = "",
  page = 0,
  size = 10,
  sort = "id",
  direction = "DESC"
) => {
  const qQuery = q ? `&q=${q}` : "";
  const statusQuery = status ? `&status=${status}` : "";
  return axios.get(
    `${API_URL}/brands?${qQuery}${statusQuery}&page=${page}&size=${size}&sort=${sort}&direction=${direction}`,
    { headers: authHeader() }
  );
};

export const getBrandById = (id) => {
  return axios.get(`${API_URL}/brands/${id}`, { headers: authHeader() });
};

///option
export const getBrandsOption = () => {
  return axios.get(`${API_URL}/brands/option`, { headers: authHeader() });
};
