import React from "react";
import AdminTemplate from "../../../templates/admin-template";
import CouponAssign from "../../../components/admin/coupons/coupon-assign";

const AdminCouponAssignPage = () => {
  return (
    <AdminTemplate>
      <CouponAssign />
    </AdminTemplate>
  );
};

export default AdminCouponAssignPage;
