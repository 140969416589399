import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import "./footer.scss";
import logo from "../../../../assets/img/logo/logo3.png";
import { settings } from "../../../../helpers/setting";
import { BsTelephone } from "react-icons/bs";
import { BiNavigation } from "react-icons/bi";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { HiOutlineMail } from "react-icons/hi";
import payment from "../../../../assets/img/payment/payment-logos.png";

const Footer = () => {
  const { pathname } = useLocation;

  return (
    <footer className="footer">
      <Container>
        <Row className="footer-top g-5 mx-0">
          <Col sm={6} lg={3}>
            <Link to="/">
              <img src={logo} alt={settings.siteName} className="img fluid" />
            </Link>
          </Col>
          <Col sm={6} lg={3}>
            <h5>About Us</h5>
            <p>
              Welcome to our e-commerce site dedicated to providing the best
              game tools for gamers of all levels. Our mission is to offer a
              wide selection of high-quality gaming products, from controllers
              to headsets, at competitive prices while providing exceptional
              customer service.
            </p>
          </Col>

          <Col sm={6} lg={3}>
            <h5>Quick Links</h5>
            <ul>
              <li>
                <Link to="/" className={pathname === "/" ? "active" : ""}>
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/shop"
                  className={pathname === "shop" ? "active" : ""}
                >
                  Products
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  className={pathname === "/contact" ? "active" : ""}
                >
                  Contact Us
                </Link>
              </li>
              <li>
                <Link
                  to="/privacy-policy"
                  className={pathname === "/privacy-policy" ? "active" : ""}
                >
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </Col>

          <Col sm={6} lg={3}>
            <h5>Contact Us</h5>
            <ul>
              <li>
                <a href={`tel:${settings.phone1}`}>
                  <BsTelephone /> {settings.phone1}
                </a>
              </li>
              <li>
                <a href={`https://wa.me/${settings.whatsapp}?text=Merhaba`}>
                  <AiOutlineWhatsApp /> {settings.whatsapp}
                </a>
              </li>
              <li>
                <a href={`mailto:${settings.email}`}>
                  <HiOutlineMail /> {settings.email}
                </a>
              </li>
              <li>
                <a href={settings.mapUrl} target="_blank" rel="noreferrer">
                  <BiNavigation /> {settings.address}
                </a>
              </li>
            </ul>
          </Col>
        </Row>
        <Row className="g-1 footer-bottom">
          <Col lg={5}>
            <h6>
              © {new Date().getFullYear()} - Copyright by gamingpromarket.com
            </h6>
          </Col>

          <Col lg={7}>
            <img src={payment} alt={settings.siteName} className="img fluid" />
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
