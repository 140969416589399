import React from "react";
import AdminUserEdit from "../../../components/admin/users/admin-user-edit";
import AdminTemplate from "../../../templates/admin-template";

const AdminUserEditPage = () => {
  return (
    <AdminTemplate>
      <AdminUserEdit />
    </AdminTemplate>
  );
};

export default AdminUserEditPage;
