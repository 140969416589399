import React from "react";
import Coupons from "../../../components/admin/coupons/coupons";
import AdminTemplate from "../../../templates/admin-template";

const AdminCouponsPages = () => {
  return (
    <AdminTemplate>
      <Coupons />
    </AdminTemplate>
  );
};

export default AdminCouponsPages;
