import React from "react";
import { Button, Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { logout } from "../../../../store/slices/auth-slice";
import { question } from "../../../../helpers/functions/swal";
import { RxPerson } from "react-icons/rx";
import {
  encryptedLocalStorage,
  encryptedSessionStorage,
} from "../../../../helpers/functions/encrypt-storage";
import "./user-menu.scss";
import { setCart } from "../../../../store/slices/cart-slice";

const UserMenu = () => {
  const { isUserLogin, user } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleLogout = async () => {
    question("Logout", "Are you sure to logout?").then((result) => {
      if (result.isConfirmed) {
        dispatch(logout());
        dispatch(setCart([]));
        encryptedLocalStorage.removeItem("token");
        encryptedSessionStorage.removeItem("token");
        localStorage.removeItem("cartUUID");
        sessionStorage.removeItem("cartUUID");
        navigate("/");
        window.location.reload();
      }
    });
  };

  return (
    <div className="user-menu">
      {isUserLogin ? (
        <Dropdown align="end">
          <Dropdown.Toggle className="dropdown-user" variant="primary">
            {user.firstName} {user.lastName}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {/* {user.roles.includes("Administrator") && (
                <>
                  <Dropdown.Item as={Link} to="/admin">
                    Admin Panel
                  </Dropdown.Item>
                  <Dropdown.Divider />
                </>
              ))} */}
            {user.roles.includes("Administrator") ? (
              <>
                <Dropdown.Item as={Link} to="/admin">
                  Admin Panel
                </Dropdown.Item>
                <Dropdown.Divider />
              </>
            ) : user.roles.includes("Manager") ? (
              <>
                <Dropdown.Item as={Link} to="/admin">
                  Manager Panel
                </Dropdown.Item>
                <Dropdown.Divider />
              </>
            ) : (
              <>
                <Dropdown.Item as={Link} to="/user">
                  User Panel
                </Dropdown.Item>
                <Dropdown.Divider />
              </>
            )}

            <Dropdown.Item as={Link} to="/user">
              Profile
            </Dropdown.Item>
            <Dropdown.Item as={Link} to="/user/account">
              Account
            </Dropdown.Item>
            <Dropdown.Item as={Link} to="/user/addresses">
              Addresess
            </Dropdown.Item>
            <Dropdown.Item as={Link} to="/user/favorites">
              Favorites
            </Dropdown.Item>
            <Dropdown.Item as={Link} to="/user/orders">
              Orders
            </Dropdown.Item>
            <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ) : (
        <Button
          className="btn btn-account "
          onClick={() => !isUserLogin && navigate("/login")}
          // as={Link}
          // to="/login"
          // active={pathname === "/login"}
        >
          <RxPerson /> Account
        </Button>
      )}
    </div>
  );
};

export default UserMenu;
