import React, { useState } from "react";
import {
  Alert,
  Badge,
  Button,
  ButtonGroup,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./brand-new.scss";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "../../../helpers/functions/swal";
import { newBrand } from "../../../api/brand-service";
import { uploadImage } from "../../../api/image-service";
import AdminPageTitle from "../common/admin-page-title";

const BrandNew = () => {
  const [imageSrc, setImageSrc] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const initialValues = {
    name: "",
    image: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .required("Please enter the title")
      .min(2, "Please enter at least 2 characters")
      .max(70, "Please enter the most 70 characters"),
    image: Yup.mixed().required("Please select an image"),
  });

  //NEW BRAND ENDPOINT
  const onSubmit = async (values) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("image", values.image);
    try {
      const resp = await uploadImage(formData);
      const imageId = resp.data.imageId;
      delete values.image;
      await newBrand(imageId, values);
      toast("Brand was created", "success");
      navigate(-1);
    } catch (err) {
      toast(err.response.data.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const handleChangeImage = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    formik.setFieldValue("image", file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setImageSrc(reader.result);
    };
  };

  return (
    <Container className="brand-new">
      <AdminPageTitle/>
      <Form noValidate onSubmit={formik.handleSubmit} className="mt-5">
        <Row className="g-5">
          <Col md={2} className="image-area">
            <img src={imageSrc} alt="" className="img-fluid" />
            <Form.Group controlId="fileSelect">
              <Form.Control
                type="file"
                name="image"
                accept=".jpg, .jpeg, .png, .webp"
                onChange={handleChangeImage}
                className="d-none"
              />
              <Form.Label className="select-img">Select Image</Form.Label>
            </Form.Group>
            <Badge bg="secondary" className="image-error">
              {formik.errors.image}
            </Badge>
          </Col>
          <Col md={9} className="input-select">
            <Form.Group className="mb-4">
              <Form.Label>Title</Form.Label>
              <Form.Control
                placeholder="Type something"
                type="text"
                {...formik.getFieldProps("name")}
                isValid={formik.touched.name && !formik.errors.name}
                isInvalid={formik.touched.name && !!formik.errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.name}
              </Form.Control.Feedback>
            </Form.Group>
            <div className="text-end">
              <ButtonGroup className="mt-5">
                <Button
                  variant="secondary"
                  type="button"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={!(formik.dirty && formik.isValid) || loading}
                >
                  {loading && <Spinner animation="border" size="sm" />} Create
                </Button>
              </ButtonGroup>
            </div>
          </Col>
        </Row>
      </Form>
      <div className="alert">
        <Alert variant="warning" className="mt-5">
          Make sure that the height of the images you want to upload is 100px.
        </Alert>
      </div>
    </Container>
  );
};

export default BrandNew;
