import { getToCart } from "../../api/shopping-cart-service";
import store from "../../store"
import { setCart } from "../../store/slices/cart-slice";

const cartHeader = () => {
  const cartUUID = localStorage.getItem("cartUUID") || sessionStorage.getItem("cartUUID");

  let header = {};
  if (cartUUID) {
    header = { cartUUID: cartUUID };
  }
  else{
    header = {cartUUID: ""};
  }

  return header;
};

export default cartHeader;

export const loadCart = async () => {
  try {
    const cartUUID = localStorage.getItem("cartUUID") || sessionStorage.getItem("cartUUID");
    const resp = await getToCart();
    if (cartUUID) {
      store.dispatch(setCart(resp.data.shoppingCartItemDTO));
    }
    else {
      localStorage.setItem("cartUUID", resp.data.cartUUID);
    }
  } catch (err) {
    console.log(err);
    store.dispatch(setCart([]));
  }
};
