export const handleClickPriceInc = (value, setSortValue, setDirectionValue) => {
  if (value === "lowest") {
    setSortValue("discountedPrice");
    setDirectionValue("ASC");
  } else if (value === "highest") {
    setSortValue("discountedPrice");
    setDirectionValue("DESC");
  } else if (value === "a-z") {
    setSortValue("title");
    setDirectionValue("ASC");
  } else if (value === "z-a") {
    setSortValue("title");
    setDirectionValue("DESC");
  } else if (value === "oldest") {
    setSortValue("createAt");
    setDirectionValue("ASC");
  } else if (value === "newest") {
    setSortValue("createAt");
    setDirectionValue("DESC");
  }
};

// export const handleClickPriceInc = (setProducts, products) => (e) => {
//   const value = e.target.value;
//   if (value === "lowest") {
//     const numAscending = [...products].sort(
//       (a, b) =>
//         a.price -
//         (a.price * a.discount) / 100 -
//         (b.price - (b.price * b.discount) / 100)
//     );
//     setProducts(numAscending);
//   } else if (value === "highest") {
//     const numDescending = [...products].sort(
//       (a, b) =>
//         b.price -
//         (b.price * b.discount) / 100 -
//         (a.price - (a.price * a.discount) / 100)
//     );
//     setProducts(numDescending);
//   } else if (value === "a-z") {
//     const strAscending = [...products].sort((a, b) =>
//       a.title > b.title ? 1 : -1
//     );
//     setProducts(strAscending);
//   } else if (value === "z-a") {
//     const strDescending = [...products].sort((a, b) =>
//       a.title > b.title ? -1 : 1
//     );
//     setProducts(strDescending);
//   } else if (value === "oldest") {
//     const sortedDates = [...products].sort(
//       (a, b) =>
//         new Date(a.createAt.split("/").reverse()) -
//         new Date(b.createAt.split("/").reverse())
//     );
//     setProducts(sortedDates);
//   } else if (value === "newest") {
//     const sortedDates = [...products].sort(
//       (a, b) =>
//         new Date(b.createAt.split("/").reverse()) -
//         new Date(a.createAt.split("/").reverse())
//     );
//     setProducts(sortedDates);
//   }
// };
