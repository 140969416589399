import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { getBrandsByPage } from "../../../api/brand-service";
import { settings } from "../../../helpers/setting";
import "./brands.scss";
import DataTable from "react-data-table-component";
import AdminPageTitle from "../common/admin-page-title";

const columns = [
  {
    selector: (row) => (
      <Link to={`/admin/brands/${row.id}`}>
        <img
          src={`${settings.apiURL}/image/display/${row.image}`}
          alt=""
          width="50"
        />
      </Link>
    ),
    width: "100px",
  },
  {
    selector: (row) => row.name,
  },
  {
    selector: (row) => row.status,
  },
];

const Brands = () => {
  const [loading, setLoading] = useState(true);
  const [brands, setBrands] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    q: "",
    status: [],
  });

  const loadData = async (page) => {
    try {
      const resp = await getBrandsByPage(
        filters.q,
        filters.status[0],
        page,
        perPage
      );
      const { content, totalElements } = resp.data;
      setBrands(content);
      setTotalRows(totalElements);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeRowsPerPage = async (newPerPage, page) => {
    try {
      const resp = await getBrandsByPage(
        filters.q,
        filters.status[0],
        page - 1,
        newPerPage
      );
      const { content } = resp.data;
      setBrands(content);
      setPerPage(newPerPage);
    } catch (err) {
      console.log(err.response.data.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (page) => {
    loadData(page - 1);
  };

  const handleRowClicked = (row) => {
    navigate(`/admin/brands/${row.id}`);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      loadData(0);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line
  }, [filters]);

  const handleFilterChange = (e) => {
    const name = e.target.name;
    const value = name === "q" ? e.target.value : [e.target.value];

    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  return (
    <Container className="admin-brands">
      <AdminPageTitle/>
      <Row className="my-5">
        <Col md={7} className="mb-1">
          <InputGroup className="searchbox">
            <Form.Control
              type="search"
              name="q"
              value={filters.q}
              onChange={handleFilterChange}
              placeholder="Type something"
            />
            <InputGroup.Text>
              <FaSearch />
            </InputGroup.Text>
          </InputGroup>
          </Col>
          <Col md={5} className="mb-1">
            <InputGroup className="selectbox">
                <Form.Select
                  name="status"
                  value={filters.status[0]}
                  onChange={handleFilterChange}
                >
                  <option value="">Select Status</option>
                  <option value="PUBLISHED">Published</option>
                  <option value="NOT_PUBLISHED">Not Published</option>
                </Form.Select>
                <Link to="/admin/brands/new-brand">
                  <Button variant="secondary">New Brand</Button>
                </Link>
            </InputGroup>
        </Col>
      </Row>
      <DataTable
        columns={columns}
        data={brands}
        // DataTable paketinin filter mevzusunu unutmamak için aşağıdaki yapı yorumda bırakılmıştır. Lütfen silmeyiniz.
        // data={brands.filter((item) => {
        //   if (searchTerm === "") {
        //     return item;
        //   } else if (
        //     item.name.toLowerCase().includes(searchTerm.toLowerCase())
        //   ) {
        //     return item;
        //   }
        // })}
        progressPending={loading}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onChangePage={handleChangePage}
        onRowClicked={handleRowClicked}
      />
    </Container>
  );
};

export default Brands;
