import React, { useState } from "react";
import { Modal, Button, Table } from "react-bootstrap";
import { getDate, getTime } from "../../../helpers/functions/date-time";
import "./order-result.scss";
import { Link } from "react-router-dom";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { settings } from "../../../helpers/setting";

const OrderResult = ({ orderResult }) => {
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);

  const {
    code,
    createAt,
    subTotal,
    discount,
    tax,
    grandTotal,
    shippingAddressDTO,
    invoiceAddressDTO,
    shippingCost,
    contactName,
    contactPhone,
    shippingDetails,
    orderItemsDTO,
    couponDiscount
  } = orderResult;

  const [logistics, trackingNumber] = shippingDetails
    ? shippingDetails.split(" : ")
    : "";

  // const discountCouponAmount =
  //   Number(grandTotal) -
  //   Number(subTotal) +
  //   Number(discount) -
  //   Number(tax) -
  //   Number(shippingCost);
  // const totalDiscount = Number(discount) - Number(discountCouponAmount);

  return (
    <Modal show={show} onHide={handleClose} className="order-result">
      <Modal.Header closeButton>
        <Modal.Title>Order Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body className="order-result-body">
        <div className="order-result-header">
          <p className="order-result-icon">
            <AiOutlineCheckCircle />
          </p>
          <p className="order-result-title">
            Your order has been placed successfully!
          </p>
          <p className="order-result-code">
            <b>Order Number:</b> {code}
          </p>
        </div>
        <div className="order-result-details">
          <p>
            <b>Date :</b> {getDate(createAt)}
          </p>
          <p>
            <b>Time :</b> {getTime(createAt)}
          </p>
          <p>
            <b>Shipping Address :</b> {shippingAddressDTO?.address}
          </p>
          <p>
            <b>Invoice Address :</b> {invoiceAddressDTO?.address}
          </p>
          <p>
            <b>Contact Name :</b> {contactName}
          </p>
          <p>
            <b>Contact Phone Number :</b> {contactPhone}
          </p>
          <p>
            <b>Cargo Company :</b> {logistics}
          </p>
          <p>
            <b>Shipping Tracking Number :</b> {trackingNumber}
          </p>
        </div>
        <Table responsive className="table align-middle">
          <thead>
            <tr>
              <th colSpan={2} className="text-start">
                Product
              </th>
              <th className="text-center">Amount</th>
              <th style={{ whiteSpace: "nowrap", textAlign: "right" }}>
                Sub Total
              </th>
            </tr>
          </thead>
          <tbody>
            {orderItemsDTO?.map((product) => (
              <tr key={product.productId}>
                <td>
                  <img
                    src={`${settings.apiURL}/image/display/${product.imageId}`}
                    alt={product.productId}
                    width="50vh"
                  />
                </td>
                <td className="text-start">{product.title}</td>
                <td className="text-center">{product.quantity}</td>
                <td className="text-end">{product.subTotal.toFixed(2)}$</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={3} className="text-end">
                <b>Sub Total :</b>
              </td>
              <td className="text-end">
                <b>{subTotal.toFixed(2)}$</b>
              </td>
            </tr>

            <tr>
              <td colSpan={3} className="text-end">
                <b>Discount :</b>
              </td>
              <td className="text-end">
                <b>{discount.toFixed(2)}$</b>
              </td>
            </tr>

              <tr>
                <td colSpan={3} className="text-end">
                  <b>Coupon Discount :</b>
                </td>
                <td className="text-end">
                  <b>{couponDiscount.toFixed(2)}$</b>
                </td>
              </tr>

            <tr>
              <td colSpan={3} className="text-end">
                <b>Tax :</b>
              </td>
              <td className="text-end">
                <b>{tax.toFixed(2)}$</b>
              </td>
            </tr>

            <tr>
              <td colSpan={3} className="text-end">
                <b>Shipping Cost :</b>
              </td>
              <td className="text-end">
                <b>{shippingCost.toFixed(2)}$</b>
              </td>
            </tr>

            <tr>
              <td colSpan={3} className="text-end">
                <b>Total Amount :</b>
              </td>
              <td className="text-end">
                <b>{grandTotal.toFixed(2)}$</b>
              </td>
            </tr>
          </tfoot>
        </Table>
        <p className="order-result-orders-link">
          You can track your orders <Link to="/user/orders"> here</Link>!
        </p>
        <p className="order-result-thank-you">
          Thank you for shopping with us!
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={handleClose}
          style={{ borderRadius: "0.3rem", color: "white" }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default OrderResult;
