import React from "react";
import Brands from "../../../components/admin/brands/brands";
import AdminTemplate from "../../../templates/admin-template";

const AdminBrandsPage = () => {
  return <AdminTemplate>
    <Brands/>
  </AdminTemplate>;
};

export default AdminBrandsPage;
