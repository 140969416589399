import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "../../../helpers/functions/swal";
import "./admin-coupon-new.scss";
import { newCoupon } from "../../../api/coupon-service";
import AdminPageTitle from "../common/admin-page-title";
const AdminCouponNew = () => {
  const [loading, setLoading] = useState(false);
  const [infinityState, setInfinityState] = useState(false);
  const navigate = useNavigate();
  const initialValues = {
    amount: "",
    type: "",
    life: "",
    status: "PASSIVE",
  };
  const validationSchema = Yup.object({
    amount: Yup.number()
      .integer()
      .min(0, "Please enter a positive integer or 0 for your amount")
      .required("Please enter your amount"),
    type: Yup.string().required("Please enter type of coupon"),
    life: Yup.number()
      .test(
        "life",
        "Please enter a positive number or -1",
        (value) => value === -1 || (value > 0 && Number.isInteger(value))
      )
      .required("Please enter coupon life"),
  });
  const onSubmit = async (values) => {
    setLoading(true);
    try {
      await newCoupon(values);
      toast("Coupon was created", "success");
      navigate(-1);
    } catch (err) {
      toast(err.response.data.message, "error");
    } finally {
      setLoading(false);
    }
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const handleInfinityChange = () => {
    formik.values.life = infinityState ? 0 : -1;
    setInfinityState((prev) => (prev ? false : true));
  };

  return (
    <Container className="coupon-new">
      <AdminPageTitle />
      <Form noValidate onSubmit={formik.handleSubmit}>
        <Row className="my-5">
          <Col sm={6}>
            <Form.Group className="mb-3">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="number"
                {...formik.getFieldProps("amount")}
                isValid={formik.touched.amount && !formik.errors.amount}
                isInvalid={formik.touched.amount && !!formik.errors.amount}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.amount}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group className="mb-3">
              <Form.Label>Type</Form.Label>
              <Form.Select
                {...formik.getFieldProps("type")}
                isValid={formik.touched.type && !formik.errors.type}
                isInvalid={formik.touched.type && !!formik.errors.type}
              >
                <option>Select Type</option>
                <option value="PERCENTAGE_AMOUNT">Percentage</option>
                <option value="EXACT_AMOUNT">Exact</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.type}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group className="mb-3">
              <Form.Label>Life</Form.Label>
              <InputGroup>
                <Form.Control
                  className="life"
                  type="number"
                  disabled={infinityState}
                  {...formik.getFieldProps("life")}
                  isValid={formik.touched.life && !formik.errors.life}
                  isInvalid={formik.touched.life && !!formik.errors.life}
                />
                <Form.Check
                  type="checkbox"
                  className="checkbox"
                  onChange={handleInfinityChange}
                  checked={infinityState}
                />
                <InputGroup.Text className="infinity">Infinity</InputGroup.Text>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.life}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group>
              <Form.Check
                type="switch"
                label="Active"
                checked={formik.values.status === "ACTIVE"}
                onChange={(e) =>
                  formik.setFieldValue(
                    "status",
                    e.target.checked ? "ACTIVE" : "PASSIVE"
                  )
                }
              />
            </Form.Group>
          </Col>
        </Row>
        <div className="text-end">
          <ButtonGroup className="mt-5">
            <Button
              variant="secondary"
              type="button"
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={!(formik.dirty && formik.isValid) || loading}
            >
              {loading && <Spinner animation="border" size="sm" />} Create
            </Button>
          </ButtonGroup>
        </div>
      </Form>
    </Container>
  );
};
export default AdminCouponNew;
