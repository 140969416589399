import React from "react";
import AdminCouponNew from "../../../components/admin/coupons/admin-coupon-new";
import AdminTemplate from "../../../templates/admin-template";

const AdminCouponNewPage = () => {
  return (
    <AdminTemplate>
      <AdminCouponNew />
    </AdminTemplate>
  );
};

export default AdminCouponNewPage;
