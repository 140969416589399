import React, { useEffect, useState } from "react";
import { getUser } from "./api/user-service";
import {
  encryptedLocalStorage,
  encryptedSessionStorage,
} from "./helpers/functions/encrypt-storage";
import { settings } from "./helpers/setting";
import LoadingPage from "./pages/common/loading-page";
import CustomRoutes from "./router/custom-routes";
import { useAppDispatch } from "./store/hooks";
import { loginFailed, loginSuccess } from "./store/slices/auth-slice";
import { loadCart } from "./helpers/functions/cart-header";
import { setItems } from "./store/slices/favorite-slice";
import { toast } from "./helpers/functions/swal";

const App = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();

  const loadUser = async () => {
    try {
      const token =
        encryptedLocalStorage.getItem("token") ||
        encryptedSessionStorage.getItem("token");
      if (token) {
        const resp = await getUser();
        dispatch(loginSuccess(resp.data));
        dispatch(setItems(resp.data.favoriteList));
      }
    } catch (err) {
      toast(err.response.data.message, "error");
      dispatch(loginFailed());
    }
  };

  const loadData = async () => {
    await loadUser();
    await loadCart();
    setLoading(false);
  };

  useEffect(() => {
    document.title = `${settings.siteName} | Your Gaming Store`;
    loadData();

    // eslint-disable-next-line
  }, []);

  return (
    <div style={{ backgroundColor: "rgb(237, 237, 237)" }}>
      {loading ? <LoadingPage /> : <CustomRoutes />}
    </div>
  );
};

export default App;
