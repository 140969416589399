import React from "react";
import "./brands.scss";
import Marquee from "react-fast-marquee";
import { Container } from "react-bootstrap";
import { getBrandsByPage } from "../../../api/brand-service";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "../../../helpers/functions/swal";
import { settings } from "../../../helpers/setting";
import Loading from "../../common/loading/loading";
import { Link, useNavigate } from "react-router-dom";

const Brands = () => {
  const [getBrands, setGetBrands] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleBrandClick = (brandId) => {
    navigate(`/shop?brands=${brandId}`);
  };

  const loadData = async () => {
    setLoading(true);
    try {
      const resp = await getBrandsByPage();
      setGetBrands(resp.data.content);
    } catch (err) {
      toast(err.response.data.message, "error");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <Container fluid className="brands">
      <Container>
        <Marquee pauseOnHover gradientWidth={100} speed={100}>
          {getBrands.map((brand, i) => (
            <Link
              to={`/shop?brands=${brand.id}`}
              key={i}
              onClick={() => handleBrandClick(brand.id)}
            >
              <img
                src={`${settings.apiURL}/image/display/${brand.image}`}
                alt=""
              />
            </Link>
          ))}
        </Marquee>
      </Container>
    </Container>
  );
};

export default Brands;
