import React from "react";
import ContactMessageEdit from "../../../components/admin/contacts/contact-message-edit";
import AdminTemplate from "../../../templates/admin-template";

const ContactMessageEditPage = () => {
  return (
    <AdminTemplate>
      <ContactMessageEdit />
    </AdminTemplate>
  );
};

export default ContactMessageEditPage;
