import { useFormik } from "formik";
import React, { useState } from "react";
import { Button, ButtonGroup, Container, Form, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { newCategory } from "../../../api/category-service";
import { toast } from "../../../helpers/functions/swal";
import "./category-new.scss";
import AdminPageTitle from "../common/admin-page-title";

const CategoryNew = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const initialValues = {
    title: "",
  };

  const validationSchema = Yup.object({
    title: Yup.string()
      .required("Please enter the title")
      .min(2, "Please enter at least 2 characters")
      .max(80, "Please enter the most 80 characters"),
  });

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      await newCategory(values);
      toast("Title was created", "success");
      navigate(-1);
    } catch (err) {
      toast(err.response.data.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <Container className="category-new">
      <AdminPageTitle />
      <Form noValidate onSubmit={formik.handleSubmit} className="mt-5">
        <Form.Group className="mb-4">
          <Form.Label>Title</Form.Label>
          <Form.Control
            placeholder="Type something"
            type="text"
            {...formik.getFieldProps("title")}
            isValid={formik.touched.title && !formik.errors.title}
            isInvalid={formik.touched.title && !!formik.errors.title}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.title}
          </Form.Control.Feedback>
        </Form.Group>

        <div className="text-end">
          <ButtonGroup className="mt-5">
            <Button
              variant="secondary"
              type="button"
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={!(formik.dirty && formik.isValid) || loading}
            >
              {loading && <Spinner animation="border" size="sm" />} Create
            </Button>
          </ButtonGroup>
        </div>
      </Form>
    </Container>
  );
};

export default CategoryNew;
