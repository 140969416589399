import React from "react";
import CategoryNew from "../../../components/admin/categories/category-new";
import AdminTemplate from "../../../templates/admin-template";

const AdminCategoryNewPage = () => {
  return (
    <AdminTemplate>
    <CategoryNew/>
    </AdminTemplate>
  );
};

export default AdminCategoryNewPage;
