import React from "react";
import BrandNew from "../../../components/admin/brands/brand-new";
import AdminTemplate from "../../../templates/admin-template";

const AdminBrandNewpage = () => {
  return (
    <AdminTemplate>
      <BrandNew />
    </AdminTemplate>
  );
};

export default AdminBrandNewpage;
