import React, { useEffect, useState } from "react";
import AdminPageTitle from "../common/admin-page-title";
import {
  Form,
  Button,
  ButtonGroup,
  Col,
  Container,
  Row,
  Spinner,
  InputGroup,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { assignCoupon, getCouponsByPage } from "../../../api/coupon-service";
import { toast } from "../../../helpers/functions/swal";
import { getAllUsers } from "../../../api/user-service";
import { useFormik } from "formik";
import * as Yup from "yup";
import Loading from "../../common/loading/loading";
import { getDate } from "../../../helpers/functions/date-time";
import DataTable from "react-data-table-component";
import "./coupon-assign.scss";
import { FaSearch } from "react-icons/fa";

const columnsCoupon = [
  {
    name: "Code",
    selector: (row) => row.code,
    minWidth: "140px",
  },
  {
    name: "Amount",
    selector: (row) => row.amount,
    center: true,
  },
  {
    name: "Type",
    selector: (row) => row.type,
    center: true,
  },
  {
    name: "Life",
    selector: (row) => (row.life === -1 ? "∞" : row.life),
    right: true,
  },
];

const columnsUser = [
  {
    name: "User",
    selector: (row) => `${row.firstName}, ${row.lastName}`,
    sortable: true,
  },
  {
    name: "Birth Date",
    selector: (row) => row.birthDate,
    sortable: true,
  },
  {
    name: "Membership Date",
    selector: (row) => getDate(row.createAt),
    sortable: true,
  },
  {
    name: "Role/Roles",
    selector: (row) => row.roles.join(" - "),
    sortable: true,
  },
];

const CouponAssign = () => {
  const [coupons, setCoupons] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [assigning, setAssigning] = useState(false);
  const [totalRowsCoupons, setTotalRowsCoupons] = useState(0);
  const [perPageCoupons, setPerPageCoupons] = useState(3);
  const [filters, setFilters] = useState({
    q: "",
    role: [],
  });
  const [birthdayChecked, setBirthdayChecked] = useState(false);
  const [anniversaryChecked, setAnniversaryChecked] = useState(false);

  const navigate = useNavigate();

  const initialValues = {
    couponId: "",
    userId: [],
    message: "",
  };

  const validationSchema = Yup.object({
    couponId: Yup.number().required("Please select a coupon code"),
    userId: Yup.array()
      .min(1, "Please select at least one user")
      .required("Please select an user"),
    message: Yup.string()
      .required("Please enter a message")
      .min(25, "Please enter at least 25 characters"),
  });

  const loadDataCoupons = async (page) => {
    try {
      const respCoupons = await getCouponsByPage(page, perPageCoupons);
      setCoupons(respCoupons.data.content);
      setTotalRowsCoupons(respCoupons.data.totalElements);
    } catch (err) {
      const message = err.response ? err.response.data.message : err;
      toast(message, "error");
    } finally {
      setLoading(false);
    }
  };

  const loadDataUsers = async () => {
    try {
      const respUsers = await getAllUsers(
        filters.q,
        filters.role[0],
        birthdayChecked,
        anniversaryChecked
      );
      setUsers(respUsers.data);
    } catch (err) {
      const message = err.response ? err.response.data.message : err;
      toast(message, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleChangeRowsPerPageCoupons = async (newPerPage, page) => {
    try {
      const respCoupons = await getCouponsByPage(page - 1, newPerPage);
      setCoupons(respCoupons.data.content);
      setPerPageCoupons(newPerPage);
    } catch (err) {
      console.log(err.response.data.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleChangePageCoupons = (page) => {
    loadDataCoupons(page - 1);
  };

  useEffect(() => {
    loadDataCoupons(0);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      loadDataUsers();
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line
  }, [filters, birthdayChecked, anniversaryChecked]);

  const handleFilterChange = (e) => {
    const name = e.target.name;
    const value = name === "q" ? e.target.value : [e.target.value];
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const onSubmit = async (values) => {
    setAssigning(true);
    try {
      await assignCoupon(values);
      toast("Coupon was assigned", "success");
      navigate(-1);
    } catch (err) {
      const message = err.response ? err.response.data.message : err;
      toast(message, "error");
    } finally {
      setAssigning(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <Container className="coupon-assign">
      <AdminPageTitle />
      {loading ? (
        <Loading />
      ) : (
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Row className="my-5">
            <Col className="mb-1">
              <Form.Control
                placeholder="Write the message about the coupon you will assign to the
              user(s)."
                type="text"
                {...formik.getFieldProps("message")}
                isValid={formik.touched.message && !formik.errors.message}
                isInvalid={formik.touched.message && !!formik.errors.message}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.message}
              </Form.Control.Feedback>
            </Col>
          </Row>
          <Row>
            <h6>Select the coupon you want to assign to the user(s)</h6>
            <Col>
              <div className="mb-3 coupon-col">
                <DataTable
                  title=""
                  columns={columnsCoupon}
                  data={coupons}
                  progressPending={loading}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRowsCoupons}
                  onChangeRowsPerPage={handleChangeRowsPerPageCoupons}
                  onChangePage={handleChangePageCoupons}
                  selectableRows
                  selectableRowsSingle
                  onSelectedRowsChange={(selectedRows) => {
                    const selectedCouponId =
                      selectedRows.selectedRows[0]?.id || "";
                    formik.setFieldValue("couponId", selectedCouponId);
                  }}
                  paginationPerPage="3"
                  paginationRowsPerPageOptions={[3, 5, 10, 20, 50, 100]}
                  highlightOnHover
                />
              </div>
            </Col>
          </Row>

          <Row className="mt-5">
            <h6>Select the user(s) to whom you want to assign the coupons</h6>
            <Row className="my-3">
              <Col md={9} className="mb-1">
                <InputGroup className="searchbox">
                  <Form.Control
                    type="search"
                    placeholder="Type something"
                    name="q"
                    value={filters.q}
                    onChange={handleFilterChange}
                  />
                  <InputGroup.Text>
                    <FaSearch />
                  </InputGroup.Text>
                </InputGroup>
              </Col>

              <Col md={3} className="mb-1">
                <InputGroup className="selectbox">
                  <Form.Select
                    name="role"
                    value={filters.role[0]}
                    onChange={handleFilterChange}
                  >
                    <option value="">Select Role</option>
                    <option value="ROLE_CUSTOMER">Customer</option>
                    <option value="ROLE_MANAGER">Manager</option>
                    <option value="ROLE_ADMIN">Admin</option>
                  </Form.Select>
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-2">
                  <Form.Check
                    type="checkbox"
                    label="Select user(s) whose membership anniversary is today"
                    checked={anniversaryChecked === true}
                    onChange={(e) => setAnniversaryChecked(e.target.checked)}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-2">
                  <Form.Check
                    type="checkbox"
                    label="Select user(s) whose birthday is today"
                    checked={birthdayChecked === true}
                    onChange={(e) => setBirthdayChecked(e.target.checked)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Col className="mb-3">
              <div className="user-col">
                <DataTable
                  title=""
                  columns={columnsUser}
                  data={users}
                  progressPending={loading}
                  selectableRows
                  onSelectedRowsChange={(selectedRows) => {
                    const selectedUserIds = selectedRows.selectedRows.map(
                      (row) => row.id
                    );
                    formik.setFieldValue("userId", selectedUserIds);
                  }}
                  highlightOnHover
                  overflowY="scroll"
                  overflowYOffset="300px"
                />
              </div>
            </Col>
          </Row>

          <div className="text-end">
            <ButtonGroup className="mt-5">
              <Button variant="secondary" onClick={() => navigate(-1)}>
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                disabled={!(formik.dirty && formik.isValid) || assigning}
              >
                {assigning && <Spinner animation="border" size="sm" />} Assign
                Coupon
              </Button>
            </ButtonGroup>
          </div>
        </Form>
      )}
    </Container>
  );
};

export default CouponAssign;

// import React, { useEffect, useState } from "react";
// import AdminPageTitle from "../common/admin-page-title";
// import {
//   Form,
//   Button,
//   ButtonGroup,
//   Col,
//   Container,
//   Row,
//   Spinner,
// } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
// import { assignCoupon, getCouponsByPage } from "../../../api/coupon-service";
// import { toast } from "../../../helpers/functions/swal";
// import { getUsersByPage } from "../../../api/user-service";
// import { useFormik } from "formik";
// import * as Yup from "yup";
// import Loading from "../../common/loading/loading";

// const CouponAssign = () => {
//   const [coupons, setCoupons] = useState([]);
//   const [users, setUsers] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [assigning, setAssigning] = useState(false);

//   const navigate = useNavigate();

//   const initialValues = {
//     couponId: "",
//     userId: [],
//     message: "",
//   };

//   const validationSchema = Yup.object({
//     couponId: Yup.number().required("Please select a coupon code"),
//     userId: Yup.array()
//       .min(1, "Please select at least one user")
//       .required("Please select an user"),
//     message: Yup.string().required("Please enter a message"),
//   });

//   const loadData = async (page) => {
//     try {
//       const respCoupons = await getCouponsByPage(page);
//       const respUsers = await getUsersByPage(page);
//       setCoupons(respCoupons.data.content);
//       setUsers(respUsers.data.content);
//     } catch (err) {
//       const message = err.response ? err.response.data.message : err;
//       toast(message, "error");
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     loadData(0);
//     // eslint-disable-next-line
//   }, []);

//   const handleSelectAllUsers = () => {
//     if (formik.values.userId.length === users.length) {
//       // Tüm kullanıcılar zaten seçili ise, tüm seçimleri iptal et
//       formik.setFieldValue("userId", []);
//     } else {
//       // Tüm kullanıcıları seç
//       const allUserIds = users.map((user) => user.id);
//       formik.setFieldValue("userId", allUserIds);
//     }
//   };

//   const onSubmit = async (values) => {
//     setAssigning(true);
//     try {
//       await assignCoupon(values);
//     } catch (err) {
//       const message = err.response ? err.response.data.message : err;
//       toast(message, "error");
//     } finally {
//       setAssigning(false);
//     }
//   };

//   const formik = useFormik({
//     initialValues,
//     validationSchema,
//     onSubmit,
//   });

//   return (
//     <Container className="coupon-assign">
//       <AdminPageTitle />
//       {loading ? (
//         <Loading />
//       ) : (
//         <Form noValidate onSubmit={formik.handleSubmit}>
//           <Row className="mt-5">
//             <Col className="mb-3">
//               <h3>Select Coupon Code</h3>
//               {coupons.map((coupon) => (
//                 <Form.Check
//                 key={coupon.id}
//                 type="checkbox"
//                 label={coupon.code}
//                 checked={formik.values.couponId === coupon.id}
//                 onChange={() => {
//                   if (formik.values.couponId === coupon.id) {
//                     // Seçili kupon zaten seçili ise, seçimi iptal et
//                     formik.setFieldValue("couponId", "");
//                   } else {
//                     // Kuponu seç
//                     formik.setFieldValue("couponId", coupon.id);
//                   }
//                 }}
//               />
//               ))}
//             </Col>
//           </Row>
//           <Row className="mt-5">
//             <Col className="mb-3">
//               <h3>Please select the users you want to assign the coupon to</h3>
//               <Button type="button" onClick={handleSelectAllUsers}>
//                 {formik.values.userId.length === users.length
//                   ? "Select None"
//                   : "Select all users"}
//               </Button>
//               {users.map((user) => (
//                 <Form.Check
//                 key={user.id}
//                 type="checkbox"
//                 label={user.firstName + " " + user.lastName}
//                 checked={formik.values.userId.includes(user.id)}
//                 onChange={(e) => {
//                   if (e.target.checked) {
//                     // Kullanıcıyı seç
//                     formik.setFieldValue("userId", [...formik.values.userId, user.id]);
//                   } else {
//                     // Kullanıcıyı iptal et
//                     formik.setFieldValue(
//                       "userId",
//                       formik.values.userId.filter((id) => id !== user.id)
//                     );
//                   }
//                 }}
//               />
//               ))}
//             </Col>
//           </Row>
//           <Row className="mt-5">
//             <Col className="mb-3">
//               <h3>Write your message about the coupon to the user.</h3>
//               <Form.Control
//                 type="text"
//                 {...formik.getFieldProps("message")}
//                 isValid={formik.touched.message && !formik.errors.message}
//                 isInvalid={formik.touched.message && !!formik.errors.message}
//               />
//               <Form.Control.Feedback type="invalid">
//                 {formik.errors.message}
//               </Form.Control.Feedback>
//             </Col>
//           </Row>

//           <div className="text-end">
//             <ButtonGroup className="mt-5">
//               <Button variant="secondary" onClick={() => navigate(-1)}>
//                 Cancel
//               </Button>
//               <Button
//                 variant="primary"
//                 type="submit"
//                 disabled={!(formik.dirty && formik.isValid) || assigning}
//               >
//                 {assigning && <Spinner animation="border" size="sm" />} Assign
//                 Coupon
//               </Button>
//             </ButtonGroup>
//           </div>
//         </Form>
//       )}
//     </Container>
//   );
// };

// export default CouponAssign;
