import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import "./coupons.scss";
import { FaSearch } from "react-icons/fa";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import { getCouponsByPage } from "../../../api/coupon-service";
import AdminPageTitle from "../common/admin-page-title";

const columns = [
  {
    name: "Code",
    selector: (row) => row.code,
    minWidth: "140px",
  },
  {
    name: "Amount",
    selector: (row) => row.amount,
    center: true,
  },
  {
    name: "Type",
    selector: (row) => row.type,
    center: true,
  },
  {
    name: "Life",
    selector: (row) => (row.life === -1 ? "∞" : row.life),
    right: true,
  },
];

const Coupons = () => {
  const [loading, setLoading] = useState(true);
  const [coupons, setCoupons] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const navigate = useNavigate();
  const [query, setQuery] = useState("");

  const loadData = async (page) => {
    try {
      const resp = await getCouponsByPage(page, perPage);
      const { content, totalElements } = resp.data;
      setCoupons(content);
      setTotalRows(totalElements);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeRowsPerPage = async (newPerPage, page) => {
    try {
      const resp = await getCouponsByPage(page - 1, newPerPage);
      const { content } = resp.data;
      setCoupons(content);
      setPerPage(newPerPage);
    } catch (err) {
      console.log(err.response.data.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (page) => {
    loadData(page - 1);
  };

  const handleRowClicked = (row) => {
    navigate(`/admin/coupons/${row.id}`);
  };

  useEffect(() => {
    loadData(0);
    // eslint-disable-next-line
  }, []);

  return (
    <Container className="coupons">
      <AdminPageTitle />
      <Row className="my-5">
        <Col md={6} xl={7} className="mb-1">
          <InputGroup>
            <Form.Control
              type="search"
              placeholder="Type something"
              onChange={(e) => {
                setQuery(e.target.value);
              }}
            />
            <InputGroup.Text>
              <FaSearch />
            </InputGroup.Text>
          </InputGroup>
        </Col>
        <Col md={6} xl={5} className="buttons">
          <InputGroup>
            <Link to="/admin/coupons/new-coupon">
              <Button variant="secondary">New Coupon</Button>
            </Link>

            <Link to="/admin/coupons/assign-coupon">
              <Button variant="primary">Assign Coupon</Button>
            </Link>
          </InputGroup>
        </Col>
      </Row>
      <DataTable
        columns={columns}
        // eslint-disable-next-line
        data={coupons.filter((val) => {
          if (query === "") {
            return val;
          } else if (
            val.code.toLocaleLowerCase().includes(query.toLocaleLowerCase()) ||
            val.type.toLocaleLowerCase().includes(query.toLocaleLowerCase())
          ) {
            return val;
          }
        })}
        progressPending={loading}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onChangePage={handleChangePage}
        onRowClicked={handleRowClicked}
      />
    </Container>
  );
};

export default Coupons;
